import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { scrollToAnchor, setLocationHref } from '../../utils';
import { Footer } from '../components/footer';
import { FooterLogo } from '../components/footerLogo';
import { HeaderSearch } from '../components/headerSearch';
import { Navigation } from '../components/navigation';
import { RightSider } from '../components/rightSider';
import CatagoryNav from './catagoryNav';
import { CommonCardWrap } from './commonCardWrap';
import DynamicInfo from './dynamicInfo';
import ExcellentVendor from './excellentVendor';
import styles from './index.less';
import { LeftSider } from './leftSider';
import { Store } from './store';
import StylePavilion from './stylePavilion';
import TodayNew from './todayNew';

const store = new Store();

const ChooseSquare = observer(() => {
  const {
    chooseGoodsList,
    galleryImgInfo,
    stylePavilionStore,
    uLikeData,
    headerStore,
    recommendedLoading,
    chooseGoodsListLoading,
    uLikeDataLoading,
    rightSiderStore,
    liveGoodsList,
    liveGoodsLoading,
  } = store;

  useEffect(() => {
    const params = location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const jumpTo = search.get('jumpTo') || '';
    setTimeout(() => scrollToAnchor(jumpTo), 1000);
    setLocationHref();
  }, []);

  const [
    pageTop,
    setPageTop,
  ] = React.useState<number>(0);

  const [
    beforeTop,
    setBeforeTop,
  ] = React.useState<number>(0);
  const [
    isRequestData,
    setIsRequestData,
  ] = React.useState<boolean>(false);

  // 检测页面滑动距离顶部距离
  const getPageScrollTop = () => {
    const clientHeight = document.body.clientHeight || document.documentElement.clientHeight;
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    const scrollHeight = document.body.scrollHeight || document.documentElement.scrollHeight;
    const scroll = scrollHeight - clientHeight - scrollTop;
    setPageTop(scrollTop);
    setBeforeTop(scrollTop);
    setIsRequestData(scroll < 100 && scroll > 50);
  };

  useEffect(() => {
    const scroll = pageTop - beforeTop;
    let dir = 'down';
    dir = scroll < 0 ? 'up' : 'down'; // 向下滚动才加载
    if (isRequestData && dir === 'down') {
      store.changeULike?.();
    }
  }, [pageTop]);

  useEffect(() => {
    window?.addEventListener('scroll', getPageScrollTop);
    return () => {
      window.removeEventListener('scroll', getPageScrollTop);
    };
  }, []);
  return (
    <div
      className={styles.chooseSquareWrap}
      id="chooseSquareWrap"
    >

      <Navigation/>
      <HeaderSearch
        showCategory={false}
        showTabs
        store={headerStore}
      />
      <div className={styles.chooseSquareContent}>
        <CatagoryNav
          catagoryNavStore={store.catagoryNavStore}
          store={store}
        />
        <DynamicInfo
          dynamicInfoStore={store.dynamicInfoStore}
          store={store}
        />
        <div className={styles.hotMarketsAndGallery}>
          <ExcellentVendor store={store}/>
          <img
            className={styles.galleryImg}
            onClick={() => {
              window.open(`/egenie-ts-vogue/exhibitionHall/index?exhibitionHallName=${galleryImgInfo?.name}&exhibitionHallId=${galleryImgInfo?.id}&exhibitionHallAddress=${galleryImgInfo?.address}`);
            }}
            src={galleryImgInfo?.picUrl}
          />
        </div>
      </div>

      <CommonCardWrap
        dataList={liveGoodsList}
        id="liveGoods"
        jumpToMore={() => {
          window.location.assign(`/egenie-ts-vogue/liveGoods/index?tabKey=liveGoods&clothes=${headerStore.clothes}`);
        }}
        loading={liveGoodsLoading}
        showMore
        store={store}
        title="直播专区"
        type="liveGoods"
      />
      <div
        className={styles.cardInfo}
        id="recentlyNewImg"
      >
        <TodayNew
          store={store}
          todayNewStore={store.todayNewStore}
        />
      </div>
      <div
        className={styles.stylePavilion}
        id="stylePavilion"
      >
        <StylePavilion
          store={store}
          stylePavilionStore={stylePavilionStore}
        />
      </div>
      <CommonCardWrap
        dataList={chooseGoodsList}
        id="peerChooseImg"
        jumpToMore={() => {
          window.location.assign(`/egenie-ts-vogue/peersChoose/index?tabKey=peersChoose&clothes=${headerStore.clothes}`);
        }}
        loading={chooseGoodsListLoading}
        showMore
        store={store}
        title="同行都在选"
        type="peerChoose"
      />
      <CommonCardWrap
        dataList={uLikeData}
        id="guessULike"
        loading={uLikeDataLoading}
        recommendedLoading={recommendedLoading}
        showMore={false}
        store={store}
        title="为你推荐"
        type="recommended"

      />
      {/* 左侧锚点导航 */}
      <LeftSider/>
      <div className={styles.pageBottom}/>
      <RightSider store={rightSiderStore}/>
      <FooterLogo/>
      <Footer/>
    </div>
  );
});

export default ChooseSquare;

