// 首页：新品，同行都在选，猜你喜欢
import { Col, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ProductCard } from '../../components';
import EmptyStatus from '../../components/empty';
import { GoodsInfoListWrap } from '../commonCardWrap';
import { MoreInfo } from '../dynamicInfo';
import type { Store } from '../store';
import styles from './index.less';
import type TodayNewStore from './store';

@observer
export default class TodayNew extends React.Component<{ store: Store;todayNewStore: TodayNewStore; }> {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element {
    const { gotoPage, rightSiderStore, headerStore } = this.props.store;
    const { loading, todayNewTabKey, changeTodayTab, todayNewsList, vendors } = this.props.todayNewStore;
    return (
      <>
        <div
          className={styles.todayNewWrap}
          id="todayNew"
        >
          <div
            className={styles.stallNameWrap}
          >
            {
              [
                {
                  vendorName: '今日新品',
                  vendorId: -1,
                },
                
              ].concat(vendors).map((item) => (
                <div
                  className={`${styles.stallName} ${todayNewTabKey === item.vendorId ? styles.selectedStall : ''}`}
                  key={item.vendorId}
                  onClick={() => {
                    if (item.vendorId === -1) {
                      return;
                    }
                    window.open(`/egenie-ts-vogue/stallDetail/index?vendorId=${item.vendorId}`);
                  }}
                  onMouseEnter={() => {
                    changeTodayTab(item.vendorId);
                  }}
                >
                  {item.vendorName}
                </div>
              ))
            }
          </div>
          <MoreInfo
            gotoPage={() => {
              window.location.assign(`/egenie-ts-vogue/newProducts/index?tabKey=newProduct&clothes=${headerStore.clothes}`);
            }}
            title="更多"
          />
        </div>
        <Spin spinning={loading}>
          <GoodsInfoListWrap
            businessType="今日新品"
            dataList={todayNewsList}
            gotoPage={gotoPage}
            loading={loading}
            recommendedLoading={false}
            rightSiderStore={rightSiderStore}
            type="todyNew"
          />
        </Spin>
      </>
    );
  }
}

