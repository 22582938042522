import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { debounce } from 'lodash';
import { action, observable } from 'mobx';
import { api, checkLoginStatus } from '../../utils';
import { HeaderStore } from '../components/headerSearch/store';
import { RightSiderStore } from '../components/rightSider/store';
import CatagoryNavStore from './catagoryNav/store';
import { CATEGORY_MAP_REVERSE, LAYOUT_DATA_MAP } from './constant';
import DynamicInfoStore from './dynamicInfo/store';
import type { IData, IDataMap, IExcellentVendor, IGalleryImg, IGoodsVoDetail, IHotMarketList, IQuerycondition } from './interface';
import StylePavilionStore from './stylePavilion/store';
import TodayNewStore from './todayNew/store';
import type { ILiveGoodsVoDetail } from '../liveGoods/interface';

export class Store {
  constructor() {
    document.title = '衫海精-选款履约一站式服务平台';
    this.dynamicInfoStore.getActiveListsNum();

    // this.dynamicInfoStore.getActiveLists();
    this.dynamicInfoStore.getAdvertLists();
    this.getGalleryImg();
    this.catagoryNavStore.queryMoreCategory();
    this.catagoryNavStore.getCategoryStyle();
    this.catagoryNavStore.queryBusinessDistrict();
    this.catagoryNavStore.queryCoreBusinessDistrict();
    this.getTabData();
    this.queryLiveGoods();
  }

  @observable public styleLoading = false;

  @observable public searchResult = false;

  @observable public pageParams: IQuerycondition = {
    page: 1,
    pageSize: 50,
    sidx: '',
    sord: '',
  };

  @observable public headerStore = new HeaderStore({ parent: this });

  @observable public stylePavilionStore = new StylePavilionStore({ parent: this });

  @observable public dynamicInfoStore = new DynamicInfoStore({ parent: this });

  @observable public catagoryNavStore = new CatagoryNavStore({ parent: this });

  @observable public todayNewStore = new TodayNewStore({ parent: this });

  @observable public rightSiderStore = new RightSiderStore({ parent: this });

  @observable public newCarouselRef: unknown;

  @observable public styleCarouselRef: unknown;

  @observable public showRoomCarouselRef: unknown;

  @observable public recentlyNews: IGoodsVoDetail[] = []; // 最近上新

  @observable public chooseGoodsList: IGoodsVoDetail[] = []; // 同行都在选

  @observable public recommendGoodsList: IGoodsVoDetail[] = []; // 推荐

  @observable public recentlyNewsLoading = false; // 最近上新loading

  @observable public chooseGoodsListLoading = false; // 同行都在选loading

  @observable public liveGoodsList = [];// 直播专区数据

  @observable public liveGoodsLoading = false;

  @observable public uLikeDataLoading = false; // dataloading

  @observable public recommendedLoading = false;

  @observable public uLikeData: IGoodsVoDetail[] = []; // 推荐

  @observable public hotMarketList: IHotMarketList[] = []; // 热门市场

  @observable public excellentVendorList: IExcellentVendor[] = [];// 优质档口

  @observable public excellentVendorLoading = false;// 是否正在请求优质档口

  @observable public galleryImgInfo: IGalleryImg;

  @observable public totalCount = 0;

  @observable public totalPageCount = 0;

  @observable public pageLike = 1;

  @observable public frontCategoryId = null;

  // 获取展厅图片
  @action public getGalleryImg = (): void => {
    request<BaseData<IGalleryImg>>({ url: `${api.getShowRoomList}` })
      .then((res) => {
        this.galleryImgInfo = res.data;
      });
  };

  // 获取最近上新，同行在选，猜你喜欢 数据
  @action public getTabData = async(): Promise<void> => {
    this.todayNewStore.loading = true;
    this.stylePavilionStore.styleLoading = true;
    this.chooseGoodsListLoading = true;
    this.uLikeDataLoading = true;
    const loginStatus = await checkLoginStatus();

    if (!loginStatus) {
      this.queryLayoutGoodsVoFn(this.headerStore.clothes);

      // 查优质档口数据
      this.getExcellentVendorList(this.headerStore.clothes);
    } else {
      request<BaseData<number>>({ url: api.getMainCategory })
        .then((res) => {
          this.headerStore.clothes = res.data;
          this.queryLayoutGoodsVoFn(res.data);
        });
    }
  };

  public queryLiveGoods = (clothes?: number) => {
    if (clothes) {
      this.frontCategoryId = CATEGORY_MAP_REVERSE[clothes];
    } else if (this.headerStore.clothes) {
      this.frontCategoryId = CATEGORY_MAP_REVERSE[this.headerStore.clothes];
    }

    this.liveGoodsLoading = true;
    request<BaseData<ILiveGoodsVoDetail[]>>({
      url: api.getLiveGoodsArea,
      method: 'POST',
      data: {
        page: 1,
        pageSize: 10,
        parentFtCategoryId: this.frontCategoryId,
        ftCategoryId: this.frontCategoryId,
      },
    })
      .then((res) => {
        this.liveGoodsLoading = false;
        this.liveGoodsList = res.data;
      });
  };

  // 获取优质档口
  public getExcellentVendorList = (categoryId: number) => {
    this.excellentVendorLoading = true;
    request<BaseData<IExcellentVendor[]>>({ url: `/api/mall/rest/square/anon/shop/site?categoryId=${categoryId}&type=1&goodsNum=3` })
      .then((res) => {
        this.excellentVendorList = res.data || [];
      })
      .finally(() => {
        this.excellentVendorLoading = false;
      });
  };

  @action public queryLayoutGoodsVoFn = (clothes: number): void => {
    this.frontCategoryId = CATEGORY_MAP_REVERSE[clothes];
    this.dynamicInfoStore.getBaoStyleList();
    this.stylePavilionStore.getStyleOverview();
    this.todayNewStore.resetVendor();
    this.todayNewStore.getRecentGoodsList();
    this.todayNewStore.getPutAwayVendor();

    // 查优质档口数据
    this.getExcellentVendorList(clothes);
    LAYOUT_DATA_MAP.map((item) => {
      this.getLayoutData(item, clothes);
    });
  };

  @action public getLayoutData = (dataMap: IDataMap, type: number) => {
    this[`${dataMap.value}Loading`] = true;
    request<BaseData<IData<IGoodsVoDetail>>>({
      url: api.getDataList,
      method: 'post',
      data: {
        ...this.pageParams,
        channelType: dataMap.key,
        parentFtCategoryId: this.frontCategoryId,
        ftCategoryId: this.frontCategoryId,
      },
    })
      .then((res) => {
        this[`${dataMap.value}Loading`] = false;
        this[dataMap.value] = (res?.data?.list || []).slice(0, dataMap.limitNum);
      })
      .catch(() => {
        this[`${dataMap.value}Loading`] = false;
      });
  };

  @action public searchPro = (): void => {
    const url = `/egenie-ts-vogue/chooseSquare/search?searchTypeKey=${this.headerStore.searchTypeKey}&searchName=${this.headerStore.searchName}`;
    window.top.egenie.openTab(url, 'chooseSquareSearchResult', '搜索');
  };

  // 换一换
  @action public changeULike = debounce((): void => {
    this.recommendedLoading = true;
    this.pageLike += 1;
    this.getULikeData({
      page: this.pageLike,
      pageSize: 50,
    });
  }, 500);

  // 获取 猜你喜欢
  @action public getULikeData = (params: { page: number; pageSize: number; channelType?: string | number; }): void => {
    request<BaseData<IData<IGoodsVoDetail>>>({
      url: api.getDataList,
      method: 'post',
      data: {
        ...this.pageParams,
        ...params,
        channelType: 1,
        parentFtCategoryId: this.frontCategoryId,
        ftCategoryId: this.frontCategoryId,
      },
    })
      .then((res) => {
        const {
          list,
          totalCount,
          page,
          totalPageCount,
        } = res.data;
        this.uLikeData = this.uLikeData.concat(list || []);
        this.totalCount = totalCount;
        this.totalPageCount = totalPageCount;
        this.pageLike = page;
        if (this.uLikeData.length === 0) {
          this.changeUlikeNoData();
        }
      })
      .catch(() => {
        this.uLikeData = [];
        this.totalCount = 0;
        this.totalPageCount = 0;
      })
      .finally(() => {
        this.recommendedLoading = false;
      });
  };

  @action public changeUlikeNoData = (): void => {
    if (this.pageLike > 1) {
      this.getULikeData({
        page: 1,
        pageSize: 10,
      });
    }
  };

  // 品类和风格馆跳搜索页
  @action public gotoSearchResultByConditon = (childId: number | string, parentId?: number, type?: string, marketName?: string): void => {
    let url = `/egenie-ts-vogue/searchResult/index?searchName=${this.headerStore.searchName}`;
    if (type === 'business') {
      url = `/egenie-ts-vogue/market/index?marketName=${marketName}&marketIds=[${childId}]`;
      url += `&businessDistrictId=${childId}`;
    } else if (type === 'nav') {
      url += `&parentCategoryId=${parentId}&clothesStyle=${childId}`;
    } else {
      if (parentId) {
        url += `&parentCategoryId=${parentId}&ftCategoryIds=${childId}`;
      } else {
        url += `&parentCategoryId=${CATEGORY_MAP_REVERSE[this.headerStore.clothes]}&clothesStyle=${childId}`;
      }
    }
    this.catagoryNavStore.toogleAllCategory(false, null, '', []);
    this.headerStore.gotoPage(url);
  };

  @action public setNewCarousel = (el: unknown): void => {
    this.newCarouselRef = el;
  };

  @action public setStyleCarousel = (el: unknown): void => {
    this.styleCarouselRef = el;
  };

  @action public setShowRoomCarousel = (el: unknown): void => {
    this.showRoomCarouselRef = el;
  };

  @action public mainPre = (e: unknown, type: string | number): void => {
    this[type]?.prev();
  };

  @action public mainNext = (e: unknown, type: string | number): void => {
    this[type]?.next();
  };

  // 跳转页面
  @action public gotoPage = (url): void => {
    this.headerStore.gotoPage(url);
  };
}
