import type { BaseData, IObj } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api, getDateInterval, replaceBar } from '../../../utils';
import type { AdvertModel, IActiveLists, IBaoStyle, ISetinterval } from '../interface';

export default class Store {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public setIntervalInstance: ISetinterval = {
    '0': null,
    '1': null,
    '2': null,
    '3': null,
    '4': null,
  };

  @observable public activeLists: IActiveLists[] = [];

  @observable public activeListsLength = 0;

  @observable public baoStyleList: IBaoStyle[][] = [];

  @observable public countDownTime: IObj = {};

  @observable public advertList: AdvertModel[] = [];

  // 获取活动信息
  @action public getActiveLists = () => {
    request<BaseData<IActiveLists[]>>({
      url: api.getActiveLists,
      params: { num: 5 },
    }).then((res) => {
      this.activeLists = Array.isArray(res.data) ? res?.data.slice(0, 5) : [];
      this.activeLists.map((item, index) => {
        if (item.beginTime && item.endTime) {
          this.getCountDownTime(index, replaceBar(item.beginTime), replaceBar(item.endTime));
        }
      });
    })
      .catch(() => this.activeLists = []);
  };

  // 获取广告信息
  @action public getAdvertLists = () => {
    request<BaseData<AdvertModel[]>>({ url: api.getAdvertList(1) }).then((res) => {
      this.advertList = res?.data || [];
    })
      .catch(() => this.activeLists = []);
  };

  // 获取总的活动个数
  @action public getActiveListsNum = (): void => {
    request<BaseData<number>>({ url: api.getActiveListsnum }).then((res) => {
      this.activeListsLength = res.data || 0;
    });
  };

  // 获取展厅信息
  @action public getBaoStyleList = () => {
    request<BaseData<IBaoStyle[]>>({
      url: api.getBaoStyle,
      params: {
        categoryId: this.parent.headerStore.clothes,
        goodsNum: 12,
      },
    }).then((res) => {
      this.baoStyleList = this.getStyleImgs(res.data || []);
    })
      .catch(() => {
        this.baoStyleList = null;
      });
  };

  // 获取需要倒计时的日期
  @action public getCountDownTime = (index: number, startTime: string, endTime: string) => {
    this.setIntervalInstance[index] = setInterval(() => {
      // 获取间日期，
      const { days,
        hours,
        minutes,
        seconds,
        distance,
        currentActivity } = getDateInterval(startTime, endTime);
      Object.assign(this.countDownTime, {
        [index]: {
          days: this.adjustTime(days),
          hours: this.adjustTime(hours),
          minutes: this.adjustTime(minutes),
          seconds: this.adjustTime(seconds),
          lastEndTime: distance,
          currentActivity,
        },
      });
      if (distance < 0) {
        clearInterval(this.setIntervalInstance[index]);
      }
    }, 1000);
  };

  @action public adjustTime = (time) => {
    if (time < 10) {
      return `0${time}`;
    }
    return time;
  };

  @action public getStyleImgs = (arr): IBaoStyle[][] => {
    // arr是空，或者数量小于4或者是4的倍数，直接返回[[]]
    const arrTmp = [];
    if (arr.length <= 4) {
      return [arr];
    } else {
      for (let i = 0; i < arr.length; i += 4) {
        arrTmp.push(arr.slice(i, i + 4));
      }
      return arrTmp;
    }
  };

  // 获取广告跳转
  @action public jumpStatistics = (advertManageId: string) => {
    request<BaseData>({ url: api.jumpStatistics(advertManageId) });
  };
}
