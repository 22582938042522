import type { BaseData, IObj } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api, PUBLIC_IMAGE_URL } from '../../../utils';
import type { IStyleOverview } from '../interface';

export default class Store {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public tabTimer;

  @observable public activeKey = 0;

  @observable public current = 0;

  @observable public styleLoading = false;

  @observable public imgTransOpen = false;

  @observable public imgUrlObj = {};

  @observable public styleCodeOverview: IStyleOverview[][] = []; // 风格

  @action public beforeChange = (from: number, to: number): void => {
    this.imgTransOpen = false;
    this.current = 0;
    this.activeKey = to;
    this.getCurrentImgs();
  };

  @action public getCurrentImgs = (): void => {
    clearInterval(this.tabTimer);
    this.tabTimer = setInterval(() => {
      this.imgTransOpen = true;
      if (this.current < 3) {
        this.current += 1;
      }
    }, 2000);
  };

  // 获取风格馆概览
  @action public getStyleOverview = (): void => {
    this.styleLoading = true;
    request<BaseData<IStyleOverview[]>>({
      url: `${api.getStyleOverview}`,
      params: { categoryId: this.parent.headerStore.clothes },
    }).then((res) => {
      this.imgUrlObj = this.getImgUrl(res.data || []);
      this.styleCodeOverview = this.getStyleImgs(res.data || []);
      this.getCurrentImgs();
    })
      .finally(() => {
        this.styleLoading = false;
      });
  };

  // 获取风格对应图片
  @action public getImgUrl = (arr: IStyleOverview[]): IObj => {
    const obj = {};
    if (!arr.length) {
      return {};
    }
    arr.map(({ backgroundCode }) => {
      if (backgroundCode) {
        obj[backgroundCode] = `${PUBLIC_IMAGE_URL}pcStyleImgs/${this.parent.headerStore.clothes}/${backgroundCode}.jpg`;
      }
    });
    return obj;
  };

  // img加载失败，调用onError，修改图片
  @action public changeImgUrl = (backgroundCode: string): void => {
    const obj = this.imgUrlObj;
    obj[backgroundCode] = `${PUBLIC_IMAGE_URL}pcStyleImgs/commonStyle.jpg`;
    this.imgUrlObj = obj;
  };

  // 风格馆自己组装数据，将返回结果每4个分成一组，用于轮播展示
  @action public getStyleImgs = (arr): IStyleOverview[][] => {
    // arr是空，或者数量小于3或者是3的倍数，直接返回[[]]
    if (arr.length === 0 || arr < 4 || arr % 4 == 0) {
      return [arr];
    } else {
      const yu = arr.length % 4; // 是否是4的倍数
      const fen = Math.ceil(arr.length / 4); // 组数，向下取整
      const xu = 4 - yu; // 需要增加的个数，凑齐4的倍数
      const before = arr.concat().slice(0, 4 * (fen - 1)); // 最后一组 之前 的数据
      const na = before.slice(-xu); // 从最后一组中取出需要的数据
      arr.splice(4 * (fen - 1), 0, ...na); // 拼成最后一组
      const arrTmp = [];
      for (let i = 0; i < arr.length; i += 4) {
        const tmp = arr.slice(i, i + 4);
        arrTmp.push(tmp);
      }
      return arrTmp;
    }
  };
}
