import type { BaseData, IObj } from 'egenie-utils';
import { request } from 'egenie-utils';
import { debounce } from 'lodash';
import { action, observable } from 'mobx';
import moment from 'moment';
import { api } from '../../../utils';
import { CATEGORY_MAP_REVERSE } from '../constant';
import type { IGoodsVoDetail, IData, IDataMap, IQuerycondition, IVendor } from '../interface';

export default class Store {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public todayNewTabKey = -1;

  @observable public todayNewsList: IGoodsVoDetail[] = [];

  @observable public loading = false;

  @observable public pageParams: IQuerycondition = {
    page: 1,
    pageSize: 10,
    sidx: '',
    sord: '',
  };

  @observable public vendors: IVendor[] = [];

  @action public getPutAwayVendor = (): void => {
    request<BaseData<IVendor[]>>({
      url: api.getPutAwayVendor,
      params: {
        categoryId: this.parent.headerStore.clothes,
        num: 6,
        putAwayStartTime: moment().subtract(3, 'days')
          .startOf('days')
          .format('YYYY-MM-DD'),
      },
    }).then((res) => {
      this.vendors = res.data;
    });
  };

  @action public changeTodayTab = debounce((key: number): void => {
    if (this.todayNewTabKey !== key) {
      this.todayNewTabKey = key;
      this.getRecentGoodsList();
    }
  }, 100);

  @action public getRecentGoodsList = (): void => {
    this.todayNewsList = [];
    this.loading = true;
    request<BaseData<IData<IGoodsVoDetail>>>({
      url: api.getRecentGoodsList,
      method: 'post',
      data: {
        ...this.pageParams,
        vendorId: this.todayNewTabKey === -1 ? undefined : this.todayNewTabKey,
        parentFtCategoryId: CATEGORY_MAP_REVERSE[this.parent.headerStore.clothes],
      },
    }).then((res) => {
      this.loading = false;
      this.todayNewsList = (res?.data?.list || []).slice(0, 10);
    })
      .catch(() => {
        this.loading = false;
      });
  };

  @action public resetVendor = () => {
    this.todayNewTabKey = -1;
  };
}
