// 首页风格馆
import { Carousel, Spin } from 'antd';
import type { IObj } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { getPrice } from '../../../utils';
import EmptyStatus from '../../components/empty';
import commonStyles from '../common.less';
import { SwiperButton } from '../dynamicInfo';
import type { gotoSearchResultByConditon, IStyleOverview } from '../interface';
import type { Store } from '../store';
import styles from './index.less';
import type StylePavilionStore from './store';

const StylePavilion = observer((props: { store: Store; stylePavilionStore: StylePavilionStore; }) => {
  const { mainPre, mainNext, setStyleCarousel, gotoSearchResultByConditon, headerStore } = props.store;
  const { styleLoading, tabTimer, current, styleCodeOverview, beforeChange, imgTransOpen, changeImgUrl, imgUrlObj } = props.stylePavilionStore;
  useEffect(() => {
    return () => {
      clearInterval(tabTimer);
    };
  }, []);
  return (
    <div
      className={styles.stylePavilionWrap}
    >
      <Spin spinning={styleLoading}>
        <div className={`${styles.stylePavilionHeader} ${commonStyles.fwBold}`}>
          <span>
            风格馆
          </span>
        </div>
        <div
          className={`${styles.styleCarousel} ${commonStyles.commonCarousel}`}
          style={{
            height: 306,
            alignItems: styleCodeOverview?.length > 0 ? 'flex-start' : 'center',
          }}
        >
          {
            styleCodeOverview?.[0]?.length > 0 && (
              <Carousel
                autoplay
                autoplaySpeed={8000}
                beforeChange={beforeChange}
                dots={false}
                ref={setStyleCarousel}
                style={{
                  width: 1164,
                  background: 'transparent',
                }}
              >
                {
                  styleCodeOverview?.map((item, index) => {
                    return (
                      <div
                        className={styles.carouselImgWrap}
                        key={item[index].styleCode}
                      >
                        {
                          item?.map((itemChild, indx) => {
                            return (
                              <StyleImgWrap
                                categoryId={headerStore.clothes}
                                changeImgUrl={changeImgUrl}
                                current={current}
                                gotoSearchResultByConditon={gotoSearchResultByConditon}
                                imgTransOpen={imgTransOpen}
                                imgUrlObj={imgUrlObj}
                                indx={indx}
                                itemChild={itemChild}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${itemChild?.styleCode}${index}`}
                              />
                            );
                          })
                        }
                      </div>
                    );
                  })
                }
              </Carousel>
            )
          }
          {
            styleCodeOverview && styleCodeOverview?.length > 1 && (
              <SwiperButton
                carouselRef="styleCarouselRef"
                mainNext={mainNext}
                mainPre={mainPre}
              />
            )
          }
          {
            (styleCodeOverview?.length === 0 && !styleLoading) && (
              <EmptyStatus/>
            )
          }
        </div>
      </Spin>
    </div>
  );
});

export default StylePavilion;

const StyleImgWrap = observer((props: { current: number; indx: number; gotoSearchResultByConditon: gotoSearchResultByConditon; imgTransOpen: boolean; itemChild: IStyleOverview; categoryId: number;imgUrlObj: IObj; changeImgUrl; }) => {
  const { current, itemChild, indx, gotoSearchResultByConditon, imgTransOpen, categoryId, imgUrlObj, changeImgUrl } = props;

  // let bgUrl = `${PUBLIC_IMAGE_URL}pcStyleImgs/${categoryId}/${itemChild.backgroundCode}.jpg` || `${PUBLIC_IMAGE_URL}pcStyleImgs/commonStyle.jpg`;
  return (
    <div
      className={`${styles.imgWrap} ${imgTransOpen ? styles.imgWrapTrans : ''} ${(current === indx) ? styles.currentImgDetail : ''}`}
      style={{ paddingRight: indx !== 3 ? 8 : 0 }}
    >
      <div
        className={styles.imgWrapC}
        onClick={(e) => {
          gotoSearchResultByConditon(itemChild.name);
        }}
      >
        <img
          className={styles.carouselImgMain}
          // eslint-disable-next-line no-return-assign
          onError={() => changeImgUrl(itemChild.backgroundCode)}
          src={imgUrlObj[itemChild.backgroundCode]}
        />
      </div>
      <div className={styles.imgDetail}>
        <ImgAndPrice
          goodsList={itemChild?.goodsList?.slice(0, 2) || []}
          indx={indx}
        />
        <ImgAndPrice
          goodsList={itemChild?.goodsList?.slice(2, 4) || []}
          ind={indx}
          style={{ marginTop: 4 }}
        />
      </div>
    </div>
  );
});

export const ImgAndPrice = observer((props) => {
  const { goodsList, indx, style } = props;
  return (
    <div
      className={styles.imgDetailTop}
      style={style}
    >
      {
        goodsList?.map(({ mainPicUrl, goodsId, discountPrice, activityPrice, wholePrice }, itIndex) => {
          const price = getPrice(wholePrice, discountPrice, activityPrice);
          return (
            <div
              className={styles.imgAndPriceWrap}
              key={goodsId}
              onClick={() => {
                window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`);
              }}
              style={{ marginLeft: itIndex > 0 ? 4 : 0 }}
            >
              <div className={styles.imgCom}>
                <img
                  alt=""
                  src={mainPicUrl}
                />
              </div>
              <div className={styles.price}>
                ¥
                {price}
              </div>
            </div>
          );
        })
      }
    </div>

  );
});
