import { observer } from 'mobx-react';
import React, { useLayoutEffect, useState } from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import EmptyStatus from '../../components/empty';
import commonStyles from '../common.less';
import type { Store } from '../store';
import styles from './index.less';
import { NoLackTag } from '../../components';

const ExcellentVendor: React.FC<{ store: Store; }> = observer((props) => {
  const {
    excellentVendorList,
    excellentVendorLoading,
  } = props.store;

  const [
    activeShopIndex,
    setActiveShopIndex,
  ] = useState(0);

  const [
    lockChange,
    setLockChange,
  ] = useState(false);// 档口切换锁

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (lockChange) {
        return;
      }
      if (activeShopIndex < excellentVendorList.length - 1) {
        setActiveShopIndex(activeShopIndex + 1);
      } else {
        setActiveShopIndex(0);
      }
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [
    excellentVendorList,
    activeShopIndex,
    lockChange,
  ]);

  const handleVendorHover = (index) => {
    setLockChange(true);
    setActiveShopIndex(index);
  };

  const handleVendorLeave = () => {
    setLockChange(false);
  };
  return (
    <div
      className={styles.hotMarketWrap}
    >
      <VendorTitle/>
      <div className={styles.contentWrap}>
        {
          excellentVendorList?.length ? excellentVendorList.map((item, index) => (
            <div
              key={item.shopId}
              onClick={() => {
                window.open(`/egenie-ts-vogue/stallDetail/index?vendorId=${item.shopId}`);
              }}
              onMouseEnter={() => {
                handleVendorHover(index);
              }}

              onMouseLeave={handleVendorLeave}

            >
              <div
                className={styles.eachVendorTitle}
                key={item.shopId}
              >
                <div className={index === activeShopIndex ? styles.eachVendorActive : null}>
                  {item.shopName}
                </div>
                <div className={styles.marketLabel}>
                  {item.marketLabelName}
                </div>
                {item.unionFlag ? <NoLackTag/> : null}
              </div>
              {
                index === activeShopIndex ? (
                  <div
                    className={styles.eachVendorImgWrapper}
                    style={{ display: item?.picList?.length ? 'flex' : 'none' }}
                  >
                    {
                      item?.picList?.length ? item.picList.map((item) => (
                        <div
                          className={styles.eachVendorImgWrap}
                          key={item.id}
                        >
                          <img
                            className={styles.eachVendorImg}
                            src={item.url}
                          />
                        </div>
                      )) : null
                    }
                  </div>
                ) : null
              }
            </div>
          ))
            : (
              excellentVendorLoading ? (
                <div>
                  优质档口加载中...
                </div>
              ) : (
                <div className={styles.empty}>
                  <EmptyStatus
                    style={{
                      width: 150,
                      height: 75,
                    }}
                    textStyle={{
                      color: '#6D6E78',
                      marginTop: 12,
                    }}
                  />
                </div>
              )
            )
        }
      </div>
    </div>
  );
});

const VendorTitle = React.memo(() => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <img
          className={styles.vendorImage}
          src={`${PUBLIC_IMAGE_URL}excellentVendor.png`}
        />
        <span className={`${styles.headerTxt} ${commonStyles.fwBold}`}>
          优质档口/工厂
        </span>
      </div>
      <span
        className={styles.moreDetail}
        onClick={() => {
          window.open('/egenie-ts-vogue/findStall/index');
        }}
      >
        <span className={styles.moreDetailTxt}>
          更多
        </span>
      </span>
    </div>
  );
});

export default ExcellentVendor;
