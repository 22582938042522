import { Anchor } from 'antd';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './index.less';

const { Link } = Anchor;

const MENU_LIST = [
  {
    id: 'root',
    title: '买手精选',
  },
  {
    id: 'liveGoods',
    title: '直播专区',
  },
  {
    id: 'recentlyNewImg',
    title: '今日新品',
  },
  {
    id: 'stylePavilion',
    title: '风格馆',
  },
  {
    id: 'peerChooseImg',
    title: '同行都在选',
  },
  {
    id: 'guessULike',
    title: '为你推荐',
  },
];

export const LeftSider = () => {
  const [
    showSider,
    setShowSider,
  ] = useState(true);

  const [
    leftDistance,
    setLeftDistance,
  ] = useState(0);

  const [
    offsetTop,
    setOffSetTop,
  ] = useState(223);// 滚动锚点偏移距离
  const scrollHeightRef = useRef<number>();// 记录上次渲染时整个页面高度

  //  计算导航条距左边距离
  const calcDistance = function() {
    const windowWidth = window.innerWidth;

    setShowSider(windowWidth > 1360);
    if (windowWidth > 1360) {
      const singleDistance = (window.innerWidth - 1200) / 2;
      setLeftDistance(singleDistance - 80);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', calcDistance);
    return () => {
      window.removeEventListener('resize', calcDistance);
    };
  }, []);
  useLayoutEffect(() => {
    calcDistance();
  }, []);

  if (document.documentElement.scrollHeight - scrollHeightRef.current === 142 && offsetTop !== 223) {
    setOffSetTop(223);
  }

  if (scrollHeightRef.current - document.documentElement.scrollHeight === 142 && offsetTop !== 76) {
    setOffSetTop(76);
  }

  scrollHeightRef.current = document.documentElement.scrollHeight;// 记录上一次的高度
  return (
    <Anchor
      className={styles.anchorWrap}
      offsetTop={offsetTop}
      style={{
        left: leftDistance,
        display: showSider ? 'block' : 'none',
      }}
      targetOffset={offsetTop}
    >
      {
        MENU_LIST.map((item, index) => (
          <Fragment key={item.id}>
            <Link
              href={`#${item.id}`}
              title={item.title}
            />
            {index !== MENU_LIST.length - 1 ? <div className={styles.divider}/> : null}
          </Fragment>
        ))
      }
    </Anchor>
  );
};
